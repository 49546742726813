'use client';

import { cookieUser, cookieUserEmail } from '@/constants';
import { Analytics } from '@/lib/mixpanel';
import { useAppStore } from '@/lib/store/provider';
import { genUUIDv4 } from '@/lib/utils';
import { useCookies } from 'next-client-cookies';
import { useCallback } from 'react';

export default function useUserCreate(emailFromUrl?: string) {
	const cookies = useCookies();
	Analytics.init();
	const user = useAppStore((store) => store.user.data);
	const upsertUser = useAppStore((store) => store.upsertUser);

	const syncUserFromDb = useCallback(
		async (email?: string) => {
			if (!email) return;
			const user = await upsertUser({ email });
			if (user?.id) {
				cookies.set(cookieUser, user.id);
				cookies.set(cookieUserEmail, user.email);
			}
			return user;
		},
		[cookies, user],
	);

	const handleUserSyncFromDb = useCallback(async (emailFromInput?: string) => {
		const emailFromCookie = cookies.get(cookieUserEmail);
		const emailFromAll =
			emailFromInput || emailFromCookie || emailFromUrl || user?.email;

		// If email exists make sure a user in db exists
		return await syncUserFromDb(emailFromAll);
	}, []);

	const handleUserCreate = useCallback(async () => {
		const uuidFromCookie = cookies.get(cookieUser);
		const emailFromCookie = cookies.get(cookieUserEmail);

		// Email from cookie, url or zustand
		const emailFromAll = emailFromUrl || user?.email || emailFromCookie;

		// Identify user temporarily
		const newUUID = genUUIDv4();
		if (!uuidFromCookie) {
			cookies.set(cookieUser, newUUID);
			Analytics.identify(newUUID);
		} else {
			Analytics.identify(uuidFromCookie);
		}

		// Do not upsert if it is already there
		if (!user?.id || !user?.email) {
			await syncUserFromDb(emailFromAll);
		}
	}, [cookies, user]);

	return { handleUserCreate, handleUserSyncFromDb };
}
